import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { RouteTransitionProvider } from 'react-route-transition';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import Loader from 'components/Loader/index';
import About from 'pages/About/index';
import Client from 'pages/Client';
import Home from 'pages/Home';
import NotFound from 'pages/NotFound';
import Footer from 'parts/Footer';

const Routes = () => {
  const { loaded } = useSelector((state) => state.loaded);
  const { status } = useSelector((state) => state.projects);

  return (
    <>
      <LastLocationProvider>
        <RouteTransitionProvider>
          {!loaded && <Loader />}
          {status === 'fetched' && (
            <>
              <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/client/:slug' render={({ match }) => <Client key={match.params.slug} />} />
                <Route exact path='/about' component={About} />
                <Route path='/404' component={NotFound} />
                <Redirect to='/404' />
              </Switch>
              <Footer />
            </>
          )}
        </RouteTransitionProvider>
      </LastLocationProvider>
    </>
  );
};

export default Routes;
