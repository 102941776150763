/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import imagesLoaded from 'imagesloaded';
import Div100vh from 'react-div-100vh';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useTransition, useTransitionHistory } from 'react-route-transition';
import { Link, Redirect, useParams } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import { useClientAnimation, useCustomAnimation } from 'animations';
import ProxyFrame from 'components/ProxyFrame/index';
import { SERVER_ROOT } from 'constants/Paths/index';
import useCallbackRef from 'hooks/useCallbackRef';
import Navbar from 'parts/Navbar/index';
import { showNav } from 'redux/actions/index';
import JScroll from 'utils/JScroll';

import './client.scss';

const Client = () => {
  const { slug } = useParams();
  const lastLocation = useLastLocation();

  const { status, data } = useSelector((state) => state.projects);

  const currentProject = data.find((el) => el.path_to_project === slug);
  const nextProject = data[data.indexOf(currentProject) + 1] || data[0];
  const bodyBg = useSelector(({ bg }) => bg.body);
  const { loaded } = useSelector((state) => state.loaded);

  const [frameColor, setFrameColor] = useState('#4d4d4d');
  const [hideHint, setHideHint] = useState(false);
  const [loading, setLoading] = useState(true);
  const [smooth, setSmooth] = useState({});
  const [nextTl] = useState(gsap.timeline({
    paused: true,
    onComplete: () => {
      changePage(undefined, `/client/${nextProject.path_to_project}`);
    },
  }));

  const nextFrame = useCallbackRef((node) => {
    const length = node.getTotalLength();

    nextTl.fromTo(node, {
      strokeDasharray: `0px ${length}`,
    }, {
      strokeDasharray: `${length}px ${length}px`,
      duration: 5,
    });
  });

  const customEase = useRef(CustomEase.create('custom', '0.76, 0, 0.24, 1'));
  const [tl] = useState(gsap.timeline({
    paused: true,
    defaults: {
      ease: customEase.current,
    },
  }));

  const proxyRef = useCallbackRef(() => {
    imagesLoaded(document.body, () => {
      if (lastLocation === null) {
        setFrameColor('#FFF');

        gsap.timeline()
          .set('[data-anim-navbar]', {
            opacity: 0,
            y: -10,
          })
          .set('[data-anim-client="heading-image"]', {
            opacity: 0,
          })
          .set('[data-anim-client="heading-title"]', {
            yPercent: 100,
          });

        tl
          .addLabel('everything')
          .to("[data-anim-client='heading-image']", {
            opacity: 1,
            duration: 1,
          }, 'everything')
          .to('.loader-proxy', {
            opacity: 0,
            duration: 1,
            onComplete: () => { setFrameColor('#4d4d4d'); },
          }, 'everything')
          .to('[data-anim-navbar]', {
            opacity: 1,
            y: 0,
            duration: 1,
          }, 'everything')
          .to('[data-anim-client="heading-title"]', {
            yPercent: 0,
            duration: 1,
          }, '-=0.5');
      }
    });
  });

  useEffect(() => {
    if (lastLocation === null && loaded) {
      tl.play();
    }
  }, [lastLocation, loaded, tl]);

  // Animation stuff
  const dispatch = useDispatch();
  const history = useTransitionHistory();
  const handler = useCustomAnimation(useClientAnimation);
  useTransition(handler);

  // Data loaded
  useEffect(() => {
    if (status === 'fetched') {
      setLoading(false);
    }
  }, [status]);

  // Smooth scroll
  useEffect(() => {
    setSmooth(new JScroll());
  }, []);

  useEffect(() => {
    if (!Object.keys(smooth).length) return false;

    smooth.init({ preload: true });

    if (!loading) {
      smooth.preload();
    }

    return () => {
      smooth.destroy();
    };
  }, [loading, smooth]);

  // Hide down arrow
  const handleScroll = useCallback(({ delta, target }) => {
    if (target > 10) {
      setHideHint(true);
    }

    if (target < 10) {
      setHideHint(false);
    }

    const scrollMax = smooth.getLimit();

    if (target === scrollMax) {
      if (!nextTl.isActive()) {
        nextTl.duration(5);
        nextTl.play();
      }
    } else if (nextTl.isActive()) {
      nextTl.duration(2);
      nextTl.reverse();
    }
  }, [nextTl, smooth]);

  // VS
  useEffect(() => {
    if (!Object.keys(smooth).length) return false;
    smooth.on('scroll', handleScroll);

    return () => {
      smooth.off('scroll', handleScroll);
    };
  }, [handleScroll, smooth]);

  // Animated page change
  const changePage = async (ev, path) => {
    if (ev) {
      ev.preventDefault();
    }

    if (nextTl.isActive()) {
      nextTl.pause();
    }

    dispatch(showNav(false));

    history.push(path);
  };

  // 404 handling
  if (!currentProject) {
    return (
      <Redirect to='/404' />
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${currentProject.title} — Projects | Arnau Jiménez`}</title>
      </Helmet>
      <section data-scroll>
        <div className='--js-scroll-inner' style={{ visibility: loaded ? 'visible' : 'hidden' }}>
          <div data-scroll-item>
            <Navbar />
            <Div100vh>
              <div className='fp-hero' data-scroll-item>
                <ProxyFrame showFrame stroke={frameColor} />
                <div className='slide-wrapper'>
                  <img ref={proxyRef} src={`${SERVER_ROOT}static/img/${currentProject.image_path}`} alt='' data-anim-client='heading-image' />
                </div>
                <div className='slide-title-container'>
                  <h3
                    className='slide-title heading-main'
                    data-anim-client='heading-title'
                  >
                    {currentProject.title}
                  </h3>
                </div>
                {!hideHint && (
                <div className='triangle-container'>
                  <div className='triangle' style={{ borderColor: bodyBg === '#FFF' ? '#090909' : '#FFF' }} />
                </div>
                )}
              </div>
            </Div100vh>
            <div className='body'>
              <div className='body-inner'>
                <div className='project-body'>
                  {currentProject.text.map((el) => (
                    <p className='project-text diff' key={`text-${el}`} data-scroll-item>
                      {el}
                    </p>
                  ))}
                  <div className='project-link diff' data-scroll-item>
                    <a href={currentProject.link} target='_blank' rel='noreferrer'>Veure projecte</a>
                  </div>
                </div>
                {currentProject.images.map((img) => (
                  <img src={`${SERVER_ROOT}static/img/${img}`} key={`image-${img}`} alt='' data-scroll-item />
                ))}
                <div className='credits diff' data-scroll-item>
                  <div className='credit-block'>
                    <p className='credit-heading'>Any</p>
                    <p className='credit-body'>{currentProject.credits.year}</p>
                  </div>
                  <div className='credit-block'>
                    <p className='credit-heading'>Rol</p>
                    <ul className='credit-body'>
                      {currentProject.credits.role.map((el) => (
                        <li key={`role-${el}`}>{el}</li>
                      ))}
                    </ul>
                  </div>
                  <div className='credit-block'>
                    <p className='credit-heading'>Tecnologia</p>
                    <ul className='credit-body'>
                      {currentProject.credits.tech.map((el) => (
                        <li key={`tech-${el}`}>{el}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='next-project diff' data-scroll-item>
              <div className='loader-next'>
                <svg width='100%' height='100%'>
                  <rect className='loader-inner' x='50%' y='50%' ref={nextFrame} data-anim-client='next-loader' />
                </svg>
              </div>
              <div className='next-project-text'>
                <div className='next-project-sub'>
                  <h6 data-anim-client='next-subtitle'>Següent projecte</h6>
                </div>
                <div className='next-project-heading'>
                  <Link to={`/client/${nextProject.path_to_project}`} onClick={(ev) => changePage(ev, `/client/${nextProject.path_to_project}`)}>
                    <h5
                      className='heading-fill heading-main'
                      data-anim-client='next-title-fill'
                    >
                      {nextProject.title}
                    </h5>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Client;
