import React, { useEffect } from 'react';

import gsap from 'gsap';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTransitionHistory } from 'react-route-transition';
import { Link } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';

import { showNav } from 'redux/actions/index';
import { handleDrag } from 'utils';

const SliderTitle = ({ project, outline }) => {
  const bodyBg = useSelector(({ bg }) => bg.body);
  const dispatch = useDispatch();

  const history = useTransitionHistory();

  const lastLocation = useLastLocation();

  useEffect(() => {
    if (lastLocation === null) {
      gsap
        .set('[data-anim-slider="title-outline-inner"], [data-anim-slider="title-fill-inner"]', {
          yPercent: 100,
        });
    }
  }, [lastLocation]);

  async function changePage(ev, path) {
    ev.preventDefault();

    dispatch(showNav(false));

    history.push(path);
  }

  return (
    <div className='slider-title'>
      {outline ? (
        <Link
          to={`/client/${project.path_to_project}`}
          onClick={(ev) => changePage(ev, `/client/${project.path_to_project}`)}
          onDragStart={handleDrag}
        >
          <div className='title-outline'>
            <h3
              data-anim-slider='title-outline'
              style={{
                WebkitTextStrokeColor: bodyBg === '#FFF' ? '#090909' : '#FFF',
              }}
              className='heading-main'
            >
              {project.title.split(' ').map((el) => {
                const pr = project.title.split(' ');
                if (pr.indexOf(el) !== 0) {
                  return (
                    <React.Fragment key={`out-${el}`}>
                      <span data-anim-slider='title-outline-inner'>&nbsp;</span>
                      <span data-anim-slider='title-outline-inner'>{el}</span>
                    </React.Fragment>
                  );
                }

                return (
                  <span data-anim-slider='title-outline-inner' key={`out-${el}`}>{el}</span>
                );
              })}
            </h3>
          </div>
        </Link>
      ) : (
        <div className={outline ? 'title-outline' : 'title-fill'}>
          <h3
            data-anim-slider='title-fill'
            style={{
              color: bodyBg === '#FFF' ? '#090909' : '#FFF',
            }}
            className='heading-main'
          >
            {project.title.split(' ').map((el) => {
              const pr = project.title.split(' ');
              if (pr.indexOf(el) !== 0) {
                return (
                  <React.Fragment key={`fill-${el}`}>
                    <span data-anim-slider='title-fill-inner'>&nbsp;</span>
                    <span data-anim-slider='title-fill-inner'>{el}</span>
                  </React.Fragment>
                );
              }

              return (
                <span data-anim-slider='title-fill-inner' key={`fill-${el}`}>{el}</span>
              );
            })}
          </h3>
        </div>
      )}

    </div>
  );
};

SliderTitle.propTypes = {
  project: PropTypes.shape({
    title: PropTypes.string,
    path_to_project: PropTypes.string,
  }).isRequired,
  outline: PropTypes.bool,
};

SliderTitle.defaultProps = {
  outline: false,
};

export default SliderTitle;
