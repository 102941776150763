export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const FETCH_PROGRESS = 'FETCH_PROGRESS';

export const CHANGE_BODY = 'CHANGE_BODY';
export const CHANGE_OV = 'CHANGE_OV';

export const SAVE_REF = 'SAVE_REF';

export const SHOW_NAV = 'SHOW_NAV';

export const CONTENT_LOADED = 'CONTENT_LOADED';
