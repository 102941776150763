/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import API_ROOT from 'constants/Api/index';
import Routes from 'pages/Routes/index';
import { getData } from 'redux/actions/index';

const App = () => {
  const bodyBg = useSelector(({ bg }) => bg.body);
  const dispatch = useDispatch();
  // Get data
  useEffect(() => {
    dispatch(getData(`${API_ROOT}showcase`));
  }, [dispatch]);

  useEffect(() => {
    document.body.style.backgroundColor = bodyBg;
    document.documentElement.style.backgroundColor = bodyBg;
  }, [bodyBg]);

  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

export default App;
