/* eslint-disable class-methods-use-this */
import gsap from 'gsap';

const store = {
  ww: window.innerWidth,
  wh: window.innerHeight,
  isDevice: navigator.userAgent.match(/Android/i)
  || navigator.userAgent.match(/webOS/i)
  || navigator.userAgent.match(/iPhone/i)
  || navigator.userAgent.match(/iPad/i)
  || navigator.userAgent.match(/iPod/i)
  || navigator.userAgent.match(/BlackBerry/i)
  || navigator.userAgent.match(/Windows Phone/i),
};

class Slider {
  constructor(el, prevState = {}) {
    this.bindAll();

    this.el = el;

    this.opts = {
      speed: 1.5,
      threshold: 50,
      ease: 0.075,
    };

    this.timeout = null;

    this.ui = {
      items: el.querySelectorAll('.slide-image'),
      titles: el.querySelectorAll('.slider-title'),
    };

    this.state = {
      target: 0,
      current: 0,
      timeout: undefined,
      currentRounded: 0,
      y: 0,
      on: {
        x: 0,
        y: 0,
      },
      off: 0,
      progress: 0,
      diff: 0,
      max: 0,
      min: 0,
      snap: 0,
      flags: {
        dragging: false,
        scrolling: false,
        inactive: true,
      },
      ...prevState,
    };

    this.items = [];

    this.events = {
      move: store.isDevice ? 'touchmove' : 'mousemove',
      up: store.isDevice ? 'touchend' : 'mouseup',
      down: store.isDevice ? 'touchstart' : 'mousedown',
      scroll: 'wheel',
    };

    this.init();
  }

  bindAll() {
    ['onDown', 'onMove', 'onUp', 'onScroll']
      .forEach((fn) => {
        this[fn] = this[fn].bind(this);
      });
  }

  init() {
    return gsap.utils.pipe(
      this.setup(),
      this.on(),
      // this.auto(),
    );
  }

  destroy() {
    this.off();
    this.state = null;
    this.items = null;
    this.opts = null;
    this.ui = null;
  }

  on() {
    const {
      move, up, down, scroll,
    } = this.events;
    // state.target / state.snap
    window.addEventListener(down, this.onDown);
    window.addEventListener(move, this.onMove);
    window.addEventListener(up, this.onUp);
    window.addEventListener(scroll, this.onScroll);
  }

  off() {
    const {
      move, up, down, scroll,
    } = this.events;

    window.removeEventListener(down, this.onDown);
    window.removeEventListener(move, this.onMove);
    window.removeEventListener(up, this.onUp);
    window.removeEventListener(scroll, this.onScroll);
  }

  setup() {
    const { state } = this;
    const { items } = this.ui;

    const {
      height: wrapWidth,
      bottom: wrapDiff,
    } = this.el.getBoundingClientRect();
    // Set bounding
    state.max = (items[items.length - 1].getBoundingClientRect().bottom - wrapWidth - wrapDiff);
    state.min = 0;

    this.tl = gsap.timeline({
      paused: true,
      defaults: {
        ease: 'linear',
      },
    })
      .fromTo('.image-list', {
        yPercent: 0,
      }, {
        yPercent: -(100 - 100 / items.length),
      }, 0)
      .fromTo('.slide-titles-list', {
        yPercent: 0,
      }, {
        yPercent: -(100 - 100 / items.length),
      }, 0)
      .fromTo('.progress-inner', {
        xPercent: -100,
      }, {
        xPercent: 100,
      }, 0);

    state.snap = state.max / (items.length - 1);
  }

  calc() {
    const { state } = this;
    if (state) {
      if (!state.flags.dragging && !state.flags.scrolling) {
        state.target = Math.round(state.target / state.snap) * state.snap;

        state.off = state.target;
      }

      state.current += (state.target - state.current) * this.opts.ease;
      state.currentRounded = Math.round(state.current * 1000) / 1000;
      state.progress = gsap.utils.wrap(0, 1, state.currentRounded / state.max);

      this.tl && this.tl.progress(state.progress);
    }
  }

  getCurrent() {
    return this.state;
  }

  render() {
    this.calc();
  }

  getPos({
    changedTouches, clientX, clientY, target,
  }) {
    const x = changedTouches ? changedTouches[0].clientX : clientX;
    const y = changedTouches ? changedTouches[0].clientY : clientY;

    return { x, y, target };
  }

  onDown(ev) {
    const { x, y } = this.getPos(ev);
    const { flags, on } = this.state;

    if (this.state.flags.scrolling) return;

    flags.dragging = true;
    flags.inactive = false;
    // this.deAuto();
    on.x = x;
    on.y = y;
  }

  onUp() {
    const { state } = this;

    state.flags.dragging = false;
  }

  onMove(ev) {
    const { x, y } = this.getPos(ev);
    const { state } = this;
    if (!state.flags.dragging) return;

    const { off, on } = state;
    const moveX = x - on.x;
    const moveY = y - on.y;

    if (Math.abs(moveY) > Math.abs(moveX) && ev.cancelable) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    // state.target = ev.changedTouches
    //   ? off - moveY * this.opts.speed
    //   : off + moveY * this.opts.speed;

    state.target = off - moveY * this.opts.speed;
  }

  onScroll(ev) {
    const { state } = this;
    const { deltaY } = ev;

    if (deltaY < 0) {
      state.target -= (20) * this.opts.speed;
    } else {
      state.target += (20) * this.opts.speed;
    }

    state.flags.scrolling = true;

    this.setNotScrolling();
  }

  setNotScrolling() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      const { state } = this;
      state.flags.scrolling = false;
      // state.on.x = state.x;
      // state.on.y = state.y;
    }, 1000);
  }
}

export default Slider;
