import { SHOW_NAV } from 'redux/constants/action-types';

const initialState = {
  show: true,
};

const showNavReducer = (state = initialState, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case SHOW_NAV:
      return {
        ...state,
        show: action.payload,
      };
    default:
      return state;
  }
};

export default showNavReducer;
