// Prevent image and anchor dragging
export const handleDrag = (ev) => {
  ev.preventDefault();
};

// Linear interpolation
export const lerp = (a, b, n) => (1 - n) * a + n * b;

// Map ranges
export const mapRange = (num, inMin, inMax, outMin, outMax) => (num - inMin)
* (outMax - outMin) / (inMax - inMin) + outMin;

export const isMobile = navigator.userAgent.match(/Android/i)
|| navigator.userAgent.match(/webOS/i)
|| navigator.userAgent.match(/iPhone/i)
|| navigator.userAgent.match(/iPad/i)
|| navigator.userAgent.match(/iPod/i)
|| navigator.userAgent.match(/BlackBerry/i)
|| navigator.userAgent.match(/Windows Phone/i);

export const distance = (x1, y1, x2, y2) => {
  const a = x1 - x2;
  const b = y1 - y2;

  return Math.hypot(a, b);
};
