import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import { useDispatch } from 'react-redux';

import { showNav } from 'redux/actions/index';

export const useHomeAnimation = () => {
  const customEase = CustomEase.create('custom', '0.76, 0, 0.24, 1');
  const handler = {
    handlers: [
      {
        from: '/',
        to: '/about',
        onLeave: async () => {
          await gsap
            .timeline()
            .addLabel('titles')
            .to('[data-anim-slider="title-outline-inner"]', {
              yPercent: 100,
              duration: 1,
              ease: customEase,
            }, 'titles')
            .to('[data-anim-slider="title-fill-inner"]', {
              yPercent: 100,
              duration: 1,
              ease: customEase,
            }, 'titles+=0.1')
            .to('[data-anim-home="main-image"]', {
              opacity: 0,
              duration: 1,
              ease: customEase,
            }, '-=1')
            .to('[data-anim-navbar]', {
              opacity: 0,
              y: -10,
            }, '-=1')
            .to('[data-anim-footer]', {
              opacity: 0,
              y: 10,
              duration: 0.5,
            }, '-=1')
            .to("[data-anim-home='progress']", {
              opacity: 0,
            }, 0);
        },
      },
      {
        from: '/about',
        to: '/',
        onEnter: async () => {
          await gsap
            .timeline()
            .fromTo('[data-anim-home="main-image"]', {
              opacity: 0,
            }, {
              opacity: 1,
              duration: 1.5,
              ease: customEase,
            })
            .to('[data-anim-frame="client-top"]', {
              opacity: 0,
              duration: 0,
            }, 0)
            .fromTo('[data-anim-slider="title-outline"]', {
              opacity: 0,
            }, {
              opacity: 1,
              duration: 1,
              ease: customEase,
            }, '-=1')
            .fromTo('[data-anim-slider="title-fill"]', {
              opacity: 0,
            }, {
              opacity: 1,
              duration: 1.5,
              ease: customEase,
            }, '-=0.4')
            .fromTo('[data-anim-navbar]', {
              opacity: 0,
              y: -10,
            }, {
              opacity: 1,
              y: 0,
              duration: 0.5,
            }, '-=2')
            .fromTo('[data-anim-footer]', {
              opacity: 0,
              y: 10,
            }, {
              opacity: 1,
              y: 0,
              duration: 0.5,
            }, '-=2')
            .fromTo("[data-anim-home='progress']", {
              opacity: 0,
            }, {
              opacity: 1,
            }, 0);
        },
      },
      {
        from: '/',
        to: /\/?client\/.*/,
        onLeave: async () => {
          await gsap
            .timeline()
            .addLabel('titles')
            .to('[data-anim-slider="title-outline-inner"]', {
              yPercent: 100,
              duration: 1,
              ease: customEase,
            }, 'titles')
            .to('[data-anim-slider="title-fill-inner"]', {
              yPercent: 100,
              duration: 1,
              ease: customEase,
            }, 'titles+=0.01')
            .to('[data-anim-frame="client-top"]', {
              opacity: 1,
              duration: 1,
              ease: customEase,
            }, '-=1')
            .to('[data-anim-home="main-image"]', {
              opacity: 0,
              duration: 1,
              ease: customEase,
            }, '-=0.5')
            .to('[data-anim-navbar]', {
              opacity: 0,
              y: -10,
            }, '-=1')
            .to('[data-anim-footer]', {
              opacity: 0,
              y: 10,
              duration: 0.5,
            }, '-=1')
            .to("[data-anim-home='progress']", {
              opacity: 0,
            }, 0);
        },
      },
      {
        from: /\/?client\/.*/,
        to: '/',
        onEnter: async () => {
          await gsap
            .timeline()
            .fromTo('[data-anim-home="main-image"]', {
              opacity: 0,
            }, {
              opacity: 1,
              duration: 1.5,
              ease: customEase,
            })
            .to('[data-anim-frame="client-top"]', {
              opacity: 0,
              duration: 1.5,
              ease: customEase,
            }, 0)
            .fromTo('[data-anim-slider="title-outline"]', {
              opacity: 0,
            }, {
              opacity: 1,
              duration: 1,
              ease: customEase,
            }, '-=1')
            .fromTo('[data-anim-slider="title-fill"]', {
              opacity: 0,
            }, {
              opacity: 1,
              duration: 1.5,
              ease: customEase,
            }, '-=0.4')
            .fromTo('[data-anim-navbar]', {
              opacity: 0,
              y: -10,
            }, {
              opacity: 1,
              y: 0,
              duration: 0.5,
            }, '-=2')
            .fromTo('[data-anim-footer]', {
              opacity: 0,
              y: 10,
            }, {
              opacity: 1,
              y: 0,
              duration: 0.5,
            }, '-=2')
            .fromTo("[data-anim-home='progress']", {
              opacity: 0,
            }, {
              opacity: 1,
            }, 0);
        },
      },
    ],
  };

  return handler;
};

// Clients
export const useClientAnimation = () => {
  const customEase = CustomEase.create('custom', '0.76, 0, 0.24, 1');
  const dispatch = useDispatch();

  const handler = {
    handlers: [
      {
        from: '/',
        to: /\/?client\/.*/,
        onEnter: async () => {
          await gsap
            .timeline({
              onComplete: () => {
                dispatch(showNav(true));
              },
            })
            .fromTo('[data-anim-navbar]', {
              opacity: 0,
              y: -10,
            }, {
              opacity: 1,
              y: 0,
              duration: 0.5,
            })
            .fromTo('[data-anim-client="heading-image"]', {
              opacity: 0,
            }, {
              opacity: 1,
              duration: 1,
              ease: customEase,
            }, 0)
            .to('[data-anim-frame="client-top"]', {
              opacity: 0,
              duration: 1.5,
              ease: customEase,
            }, 0)
            .fromTo('[data-anim-client="heading-title"]', {
              yPercent: 100,
            }, {
              yPercent: 0,
              stagger: 0.05,
              duration: 0.9,
              ease: customEase,
            }, '-=0.5');
        },
      },
      {
        from: /\/?client\/.*/,
        to: '/',
        onLeave: async () => {
          await gsap
            .timeline()
            .to('[data-anim-client="heading-title"]', {
              yPercent: -100,
              stagger: 0.05,
              duration: 0.9,
              ease: customEase,
            })
            .to('[data-anim-frame="client-top"]', {
              opacity: 1,
              duration: 1,
              ease: customEase,
            }, '-=0.8')
            .to('[data-anim-client="heading-image"]', {
              opacity: 0,
              duration: 1,
              ease: customEase,
            }, '-=1')
            .to('[data-anim-navbar]', {
              opacity: 0,
              y: -10,
            }, '-=0.5');
        },
      },
      {
        from: /\/?client\/.*/,
        to: /\/?client\/.*/,
        onEnter: async () => {
          window.scrollTo({ top: 0, behavior: 'auto' });

          await gsap
            .timeline()
            .fromTo('[data-anim-client="heading-image"]', {
              opacity: 0,
            }, {
              opacity: 1,
              duration: 1,
              ease: customEase,
            }, 0)
            .fromTo('[data-anim-frame="client-top"]', {
              opacity: 1,
            },
            {
              opacity: 0,
              duration: 1,
            }, '-=0.5')
            .fromTo('[data-anim-navbar]', {
              opacity: 0,
              y: -10,
            }, {
              opacity: 1,
              y: 0,
              duration: 0.5,
            }, '-=0.5');
        },
        onLeave: async () => {
          window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
          const length = document.querySelector("[data-anim-client='next-loader']").getTotalLength();

          await gsap
            .timeline()
            .to('[data-anim-client="next-subtitle"]', {
              y: -10,
              opacity: 0,
              duration: 0.5,
            })
            .to("[data-anim-client='next-loader']", {
              strokeDasharray: `${length}px ${length}px`,
            }, 0);
        },
      },
    ],
  };

  return handler;
};

export const useAboutAnimation = () => {
  const customEase = CustomEase.create('custom', '0.76, 0, 0.24, 1');

  const handler = {
    handlers: [
      {
        path: '/about',
        onEnter: async () => {
          await gsap
            .timeline({ defaults: { ease: customEase, duration: 1 } })
            .addLabel('everything')
            .fromTo('[data-anim-about="image"]', {
              opacity: 0,
            }, {
              opacity: 1,
              duration: 1,
            }, 'everything')
            .fromTo('[data-anim-about="heading-s"] [data-word]', {
              yPercent: 100,
            }, {
              yPercent: 0,
              stagger: 0.05,
              duration: 1,
            }, 'everything')
            .fromTo('[data-anim-about="heading-f"] [data-word]', {
              yPercent: 100,
            }, {
              yPercent: 0,
              stagger: 0.05,
              duration: 1,
            }, 'everything+=0.1')
            .fromTo('.word > span', {
              yPercent: 100,
            }, {
              yPercent: 0,
              stagger: 0.02,
              duration: 1.5,
            }, 'everything')
            .fromTo("[data-anim-about='items'] > *", {
              yPercent: 100,
            }, {
              yPercent: 0,
              stagger: 0.05,
            }, 'everything+=1.2')
            .fromTo("[data-anim-about='contact']", {
              y: -10,
              opacity: 0,
            }, {
              y: 0,
              opacity: 1,
            }, 'everything+=1.2')
            .fromTo('[data-anim-about="copy"]', {
              yPercent: 100,
            }, {
              yPercent: 0,
            }, 'everything+=1.2')
            .fromTo('[data-anim-navbar]', {
              opacity: 0,
              y: -10,
            }, {
              opacity: 1,
              y: 0,
              duration: 1,
            }, 'everything+=1.5');

          /**
           *
        gsap.timeline()

           */
        },
        onLeave: async () => {
          await gsap
            .timeline({ defaults: { ease: customEase, duration: 1 } })
            .addLabel('everything')
            .to('[data-anim-about="image"]', {
              opacity: 0,
            }, 'everything')
            .to('[data-anim-about="heading-s"] [data-word]', {
              yPercent: 100,
            }, 'everything')
            .to('[data-anim-about="heading-f"] [data-word]', {
              yPercent: 100,
            }, 'everything+=0.1')
            .to('.word > span', {
              yPercent: 100,
            }, 'everything')
            .to("[data-anim-about='items'] > *", {
              yPercent: 100,
            }, 'everything+=0.2')
            .to("[data-anim-about='contact']", {
              y: -10,
              opacity: 0,
            }, 'everything+=0.2')
            .to('[data-anim-about="copy"]', {
              yPercent: 100,
            }, 'everything+=0.2')
            .to('[data-anim-navbar]', {
              opacity: 0,
              y: -10,
            }, 'everything');
        },
      },
    ],
  };

  return handler;
};

const mobileAnimation = () => {
  const handler = {
    handlers: [
      {
        path: /.*/,
        onEnter: async () => {
          window.scrollTo({ top: 0, behavior: 'auto' });
          await gsap
            .timeline()
            .fromTo('#app', {
              opacity: 0,
            }, {
              opacity: 1,
            });
        },
        onLeave: async () => {
          await gsap
            .timeline()
            .fromTo('#app', {
              opacity: 1,
            }, {
              opacity: 0,
            });
        },
      },
    ],
  };

  return handler;
};

export const useCustomAnimation = (anim) => (window.innerWidth < 992 ? mobileAnimation() : anim());
