import React, {
  useEffect, useRef, useState,
} from 'react';

import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import imagesLoaded from 'imagesloaded';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useTransition } from 'react-route-transition';
import { useLastLocation } from 'react-router-last-location';

import { useHomeAnimation, useCustomAnimation } from 'animations/index';
import Perspective from 'components/Perspective/index';
import ProxyFrame from 'components/ProxyFrame/index';
import Slider from 'components/Slider';
import SliderTitle from 'components/Slider/SliderTitle';
import { SERVER_ROOT } from 'constants/Paths';
import useCallbackRef from 'hooks/useCallbackRef';
import Navbar from 'parts/Navbar/index';
import { handleDrag } from 'utils';

import './slider.scss';

gsap.registerPlugin(CustomEase);

const Home = () => {
  const lastLocation = useLastLocation();

  const projects = useSelector((state) => state.projects.data);

  const bodyBg = useSelector(({ bg }) => bg.body);
  const { loaded } = useSelector((state) => state.loaded);

  const [slider, setSlider] = useState({});
  const [follow, setFollow] = useState({});
  const [frameColor, setFrameColor] = useState('#4d4d4d');

  const customEase = useRef(CustomEase.create('custom', '0.76, 0, 0.24, 1'));
  const [tl] = useState(gsap.timeline({
    paused: true,
    defaults: {
      ease: customEase.current,
    },
  }));

  const slides = useRef();
  const proxyRef = useCallbackRef(() => {
    imagesLoaded(document.body, () => {
      setSlider(new Slider(slides.current));
      setFollow(new Perspective());

      if (lastLocation === null) {
        setFrameColor('#FFF');

        gsap.timeline()
          .set('[data-anim-navbar]', {
            opacity: 0,
            y: -10,
          })
          .set('[data-anim-footer]', {
            opacity: 0,
            y: 10,
          })
          .set('[data-anim-home="main-image"]', {
            opacity: 0,
          });

        tl
          .addLabel('everything')
          .to("[data-anim-home='main-image']", {
            opacity: 1,
            duration: 1,
          }, 'everything')
          .to('.loader-proxy', {
            opacity: 0,
            duration: 1,
            onComplete: () => { setFrameColor('#4d4d4d'); },
          }, 'everything')
          .to('[data-anim-navbar]', {
            opacity: 1,
            y: 0,
            duration: 1,
          }, 'everything')
          .to('[data-anim-footer]', {
            opacity: 1,
            y: 0,
            duration: 1,
          }, 'everything')
          .set("[data-anim-home='progress']", {
            opacity: 1,
          }, 'everything')
          .addLabel('titles')
          .to('[data-anim-slider="title-outline-inner"]', {
            yPercent: 0,
            stagger: 0.05,
            duration: 1,
          }, 'titles')
          .to('[data-anim-slider="title-fill-inner"]', {
            yPercent: 0,
            stagger: 0.05,
            duration: 1,
          }, 'titles+=0.1');
      }
    });
  });

  // Perspective
  useEffect(() => {
    if (!Object.keys(follow).length) return false;

    const tick = () => {
      follow.render();
    };

    gsap.ticker.add(tick);

    return () => {
      gsap.ticker.remove(tick);
      follow.destroy();
    };
  }, [follow]);

  // Page enter first load
  useEffect(() => {
    if (lastLocation === null && loaded) {
      tl.play();
    }
  }, [lastLocation, loaded, tl]);

  // Page animation handler
  const handler = useCustomAnimation(useHomeAnimation);
  useTransition(handler);

  // Slider
  useEffect(() => {
    if (!Object.keys(slider).length) return false;

    const tick = () => {
      slider.render();
    };

    gsap.ticker.add(tick);

    return () => {
      gsap.ticker.remove(tick);
      slider.destroy();
    };
  }, [slider]);

  return (
    <>
      <Helmet>
        <title>Home | Arnau Jiménez</title>
      </Helmet>
      <Navbar />
      <div className='slides' ref={slides} style={{ visibility: loaded ? 'visible' : 'hidden' }}>
        <div className='slider-center'>
          <div className='slide-titles --js-perspective' style={{ zIndex: 5 }}>
            <div className='title-proxy heading-main'>Lorem Ipsum Ips</div>
            <div className='slide-titles-list list-out'>
              {projects.map((project, i) => {
                if (i === projects.length - 1) {
                  return (
                    <React.Fragment key='go-rep'>
                      <SliderTitle project={project} outline />
                      <SliderTitle project={projects[0]} outline />
                    </React.Fragment>
                  );
                }
                return (
                  <SliderTitle project={project} outline key={`outline-${project.id}`} />
                );
              })}
            </div>
          </div>
          <div className='slide-titles --js-perspective-fill'>
            <div className='title-proxy heading-main'>Lorem Ipsum Ips</div>
            <div className='slide-titles-list'>
              {projects.map((project, i) => {
                if (i === projects.length - 1) {
                  return (
                    <React.Fragment key='gf-rep'>
                      <SliderTitle project={project} />
                      <SliderTitle project={projects[0]} />
                    </React.Fragment>
                  );
                }
                return (
                  <SliderTitle project={project} key={`fill-${project.id}`} />
                );
              })}
            </div>
          </div>
          <div className='image-wrapper --js-perspective-image'>
            <img
              className='slide-image-proxy'
              src={projects[0] ? `${SERVER_ROOT}static/img/${projects[0].image_path}` : '#'}
              alt=''
              draggable='false'
              onDragStart={handleDrag}
            />
            <div className='image-list'>
              {projects.map((project, i) => {
                if (i === projects.length - 1) {
                  return (
                    <React.Fragment key='gi-rep'>
                      {/* <SliderImage project={project} />
                      <SliderImage project={projects[0]} /> */}
                      <img
                        key={`image-rep-${project.id}`}
                        className='slide-image'
                        src={`${SERVER_ROOT}static/img/${project.image_path}`}
                        alt=''
                        draggable='false'
                        onDragStart={handleDrag}
                        data-anim-home='main-image'
                      />
                      <img
                        key={`image-${projects[0].id}`}
                        className='slide-image'
                        src={`${SERVER_ROOT}static/img/${projects[0].image_path}`}
                        alt=''
                        draggable='false'
                        onDragStart={handleDrag}
                        data-anim-home='main-image'
                        ref={proxyRef}
                        // onLoad={handleLoad}
                      />
                    </React.Fragment>
                  );
                }
                return (
                  // <SliderImage key={`image-${project.id}`} project={project} />
                  <img
                    key={`image-${project.id}`}
                    className='slide-image'
                    src={`${SERVER_ROOT}static/img/${project.image_path}`}
                    alt=''
                    draggable='false'
                    onDragStart={handleDrag}
                    data-anim-home='main-image'
                  />
                );
              })}
            </div>
          </div>
        </div>
        <ProxyFrame showFrame stroke={frameColor} />
        <div className='progress' data-anim-home='progress'>
          <div
            className='progress-inner'
            style={{
              backgroundColor: bodyBg === '#FFF' ? '#090909' : '#FFF',
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Home;
