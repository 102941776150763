import { SERVER_ROOT } from 'constants/Paths/index';
import {
  CHANGE_BODY, CHANGE_OV, CONTENT_LOADED, FETCH_ERROR, FETCH_PROGRESS,
  FETCH_REQUEST, FETCH_SUCCESS, SAVE_REF, SHOW_NAV,
} from 'redux/constants/action-types';

export const getData = (url) => async (dispatch) => {
  dispatch(fetchRequest());

  try {
    const response = await fetch(url);
    const data = await response.json();

    const images = [];

    let progress = 0;
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;

        loadImg.onload = () => {
          resolve(image);
          progress += 1;
          dispatch(fetchProgress(progress / (images.length / 100)));
        };
        loadImg.onerror = (err) => reject(err);
      });
    };

    data.forEach((obj) => {
      images.push(loadImage(`${SERVER_ROOT}static/img/${obj.image_path}`));
      obj.images.forEach((img) => {
        images.push(loadImage(`${SERVER_ROOT}static/img/${img}`));
      });
    });

    Promise.all(images)
      .then(() => {
        dispatch(fetchSuccess(data));
      });
  } catch (error) {
    dispatch(fetchError(error.message));
  }
};

const fetchRequest = () => ({
  type: FETCH_REQUEST,
});

const fetchSuccess = (payload) => ({
  type: FETCH_SUCCESS,
  payload,
});

const fetchError = (error) => ({
  type: FETCH_ERROR,
  payload: error,
});

const fetchProgress = (payload) => ({
  type: FETCH_PROGRESS,
  payload,
});

// Color change
export const bgColor = () => ({
  type: CHANGE_BODY,
});

export const ovColor = () => ({
  type: CHANGE_OV,
});

// Ref storing
export const transitionRef = (ref) => ({
  type: SAVE_REF,
  payload: ref,
});

// Nav show
export const showNav = (bool) => ({
  type: SHOW_NAV,
  payload: bool,
});

// Everything preloaded
export const contentLoaded = () => ({
  type: CONTENT_LOADED,
});
