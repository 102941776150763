import { CHANGE_OV } from 'redux/constants/action-types';

const initialState = {
  overlay: '#FFF',
};

const ovReducer = (state = initialState, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case CHANGE_OV:
      return {
        ...state,
        overlay: state.overlay === '#FFF' ? '#090909' : '#FFF',
      };
    default:
      return state;
  }
};

export default ovReducer;
