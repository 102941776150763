import React, { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useTransitionHistory } from 'react-route-transition';
import { Link, useLocation } from 'react-router-dom';

import logo from 'assets/img/ajb-logo.svg';
import AnimatedAnchor from 'components/AnimatedAnchor/index';
import { showNav } from 'redux/actions/index';
import { handleDrag } from 'utils';

import './navbar.scss';

const Navbar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useTransitionHistory();
  const { loaded } = useSelector((state) => state.loaded);

  async function changePage(ev, path) {
    ev.preventDefault();
    dispatch(showNav(false));
    window.scrollTo({ top: 0, behavior: 'smooth' });
    history.push(path);
  }

  return (
    <>
      <nav data-scroll-item id='navigation' style={{ visibility: loaded ? 'visible' : 'hidden' }}>
        <div className='nav-container'>
          <div className='logo-container'>
            <div className='logo'>
              <Link
                className='logo-anchor _target'
                to='/'
                onClick={(ev) => changePage(ev, '/')}
                onDragStart={handleDrag}
              >
                <object data={logo} data-anim-navbar='logo' type='image/svg+xml' title='AJB Logo' />
              </Link>
            </div>
          </div>
          <div className='menu-container' data-anim-navbar='menu'>
            {(location.pathname === '/' || location.pathname === '/404') ? (
              <ul className='menu-items'>
                <li className='menu-item _target'>
                  <AnimatedAnchor
                    to='/about'
                    onClick={(ev) => changePage(ev, '/about')}
                    onDragStart={handleDrag}
                    next='Sobre mi'
                    route
                  >
                    Sobre mi
                  </AnimatedAnchor>
                </li>
              </ul>
            ) : (
              <ul className='menu-items'>
                <li className='menu-item _target'>
                  <Link
                    to='/'
                    onClick={(ev) => changePage(ev, '/')}
                    onDragStart={handleDrag}
                  >
                    <span className='burger' />
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
