import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';

export default class Progress {
  constructor(el, changePage, location) {
    this.el = el;
    this.length = this.el.getTotalLength();

    this.changePage = changePage;

    this.tl = {};
    this.doneTl = {};
    this.progressTl = {};

    this.state = {
      target: 0,
      current: 0,
      currentRounded: 0,
      flags: {
        loaded: false,
        done: false,
        about: location === '/about',
      },
    };

    this.opts = {
      speed: 1.5,
      threshold: 50,
      ease: 0.075,
    };

    this.setup();
  }

  setup() {
    this.tl = gsap.timeline({
      defaults: {
        ease: CustomEase.create('custom', '0.76, 0, 0.24, 1'),
      },
      onComplete: () => {
        if (!this.state.flags.loaded) {
          this.tl.restart();
        } else {
          gsap.set('.loader-inner.bg', { opacity: 0 });
          this.doneTl.play();
        }
      },
    })
      .fromTo(this.el.parentElement, {
        rotate: '0deg',
      }, {
        rotate: '180deg',
        duration: 1.5,
      })
      .fromTo(this.el.parentElement, {
        rotate: '180deg',
      }, {
        rotate: '360deg',
        duration: 1.5,
      });

    this.progressTl = gsap.timeline({
      paused: true,
    })
      .fromTo(this.el, {
        strokeDasharray: `0px ${this.length}px`,
        strokeDashoffset: 0,
      }, {
        strokeDasharray: `${this.length}px ${this.length}px`,
        strokeDashoffset: 0,
      });

    if (!this.state.flags.about) {
      this.doneTl = gsap.timeline({
        paused: true,
        defaults: {
          ease: CustomEase.create('custom', '0.76, 0, 0.24, 1'),
        },
        onComplete: () => {
          this.changePage();
        },
      })
        .set(this.el, {
          strokeDasharray: '0px 0px',
          strokeDashoffset: 0,
        })
        .to(this.el, {
          width: 350,
          x: -175,
          duration: 1,
        })
        .to(this.el, {
          height: 500,
          y: -250,
          duration: 1,
        });
    } else {
      this.doneTl = gsap.timeline({
        paused: true,
        defaults: {
          ease: CustomEase.create('custom', '0.76, 0, 0.24, 1'),
        },
        onComplete: () => {
          this.changePage();
        },
      })
        .set(this.el, {
          strokeDasharray: '0px 0px',
          strokeDashoffset: 0,
        })
        .to(this.el, {
          opacity: 0,
          duration: 1.5,
        });
    }
  }

  render() {
    const { state } = this;

    if (state.progress === 1) {
      state.flags.loaded = true;
    }

    state.current += (state.target - state.current) * this.opts.ease;
    state.currentRounded = Math.round(state.current * 1000) / 1000;
    state.progress = state.currentRounded / 100;

    this.progressTl && this.progressTl.progress(state.progress);
    // }
  }

  updateProgress(val) {
    if (val >= this.state.target) {
      this.state.target = val;
    }
  }
}
