/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './animated-anchor.scss';

const AnimatedAnchor = ({
  route, next, children, ...rest
}) => {
  return (
    <>
      {route
        ? (
          <Link
            className='anchor-wrapper'
            {...rest}
          >
            <span className='anchor-inner' data-text={next}>
              {children}
            </span>
          </Link>
        )
        : (
          <a className='anchor-wrapper' {...rest}>
            <span className='anchor-inner' data-text={next}>
              {children}
            </span>
          </a>
        )}
    </>
  );
};

AnimatedAnchor.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  next: PropTypes.string.isRequired,
  route: PropTypes.bool,
};

AnimatedAnchor.defaultProps = {
  route: false,
};

export default AnimatedAnchor;
