import React from 'react';

import { Link } from 'react-router-dom';

import Navbar from 'parts/Navbar/index';

import './404.scss';

const NotFound = () => (
  <>
    <Navbar />
    <div className='notfound-wrapper diff'>
      <h3 className='notfound-heading'>404</h3>
      <p className='notfound-subtitle'>
        Did you get lost?
        {' '}
        <Link className='notfound-home' to='/'>Get back home</Link>
      </p>
    </div>
  </>
);

export default NotFound;
