/* eslint-disable no-param-reassign */
import { distance, lerp } from 'utils/index';

/* eslint-disable class-methods-use-this */
export default class Perspective {
  constructor(opts = {}) {
    this.bindAll();

    // this.items = [...document.querySelectorAll('.--js-perspective')];
    this.item = document.querySelector('.--js-perspective');
    this.dupe = document.querySelector('.--js-perspective-fill');
    this.dupe2 = document.querySelector('.--js-perspective-image');

    this.opts = {
      ease: 0.15,
      ...opts,
    };

    this.state = {
      renderedStyles: {
        tx: {
          previous: 0,
          current: 0,
          amt: 0.1,
        },
        ty: {
          previous: 0,
          current: 0,
          amt: 0.1,
        },
      },
      on: {
        x: 0,
        y: 0,
      },
      flags: {
        paused: true,
      },
    };

    this.init();
  }

  bindAll() {
    ['onMove']
      .forEach((fn) => { this[fn] = this[fn].bind(this); });
  }

  init() {
    this.on();
    this.getDimensions();
  }

  getDimensions() {
    this.rect = this.item.getBoundingClientRect();

    this.triggerDist = this.rect.width * 0.5;
  }

  on() {
    window.addEventListener('mousemove', this.onMove);
  }

  off() {
    window.removeEventListener('mousemove', this.onMove);
  }

  destroy() {
    this.off();
  }

  getPos({ clientX, clientY }) {
    return {
      x: clientX,
      y: clientY,
    };
  }

  onMove(ev) {
    const { x, y } = this.getPos(ev);
    const { state } = this;

    state.flags.paused = false;
    state.on.x = x;
    state.on.y = y;
  }

  pause() {
    this.state.flags.paused = true;
  }

  resume() {
    this.state.flags.paused = false;
  }

  render() {
    const { state } = this;
    const distanceMouseElement = distance(
      state.on.x,
      state.on.y,
      this.rect.left + this.rect.width / 2,
      this.rect.top + this.rect.height / 2,
    );

    let x = 0;
    let y = 0;

    // if (distanceMouseElement < this.triggerDist && !state.flags.paused) {
    //   x = (state.on.x - (this.rect.left + this.rect.width / 2)) * 0.3;
    //   y = (state.on.y - (this.rect.top + this.rect.height / 2)) * 0.3;
    // }

    if (!state.flags.paused) {
      x = (state.on.x - (this.rect.left + this.rect.width / 2)) * 0.3;
      y = (state.on.y - (this.rect.top + this.rect.height / 2)) * 0.3;
    }

    state.renderedStyles.tx.current = x;
    state.renderedStyles.ty.current = y;

    for (const key in state.renderedStyles) {
      if ({}.hasOwnProperty.call(state.renderedStyles, key)) {
        state.renderedStyles[key].previous = lerp(
          state.renderedStyles[key].previous,
          state.renderedStyles[key].current,
          state.renderedStyles[key].amt,
        );
      }
    }

    this.item.style.transform = `translate3d(${state.renderedStyles.tx.previous * 0.5}px, ${state.renderedStyles.ty.previous * 0.5}px, 0px)`;
    this.dupe.style.transform = `translate3d(${state.renderedStyles.tx.previous * 0.5}px, ${state.renderedStyles.ty.previous * 0.5}px, 0px)`;
    this.dupe2.style.transform = `translate3d(${state.renderedStyles.tx.previous * 0.2}px, ${state.renderedStyles.ty.previous * 0.2}px, 0px)`;
  }
}
