import React from 'react';

import PropTypes from 'prop-types';

import './proxy-frame.scss';

const ProxyFrame = ({ showFrame, stroke }) => {
  return (
    <>
      {showFrame && (
        <div className='loader-proxy' data-anim-frame='client-top'>
          <svg width='100%' height='100%'>
            <rect stroke={stroke} className='loader-inner' x='50%' y='50%' />
          </svg>
        </div>
      )}
    </>
  );
};

ProxyFrame.propTypes = {
  showFrame: PropTypes.bool.isRequired,
  stroke: PropTypes.string,
};

ProxyFrame.defaultProps = {
  stroke: '#FFF',
};

export default ProxyFrame;
