import React, { useCallback, useEffect, useState } from 'react';

import gsap from 'gsap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useCallbackRef from 'hooks/useCallbackRef';
import { contentLoaded } from 'redux/actions/index';

import Progress from './progress';

import './loader.scss';

const Loader = () => {
  const dispatch = useDispatch();
  const { progress } = useSelector((state) => state.projects);

  const location = useLocation();

  const [progressObj, setProgressObj] = useState({});

  // Dispatch everything preloaded
  const assetsLoaded = useCallback(() => {
    dispatch(contentLoaded());
  }, [dispatch]);

  // Store progress instance
  const fg = useCallbackRef((node) => {
    setProgressObj(new Progress(node, assetsLoaded, location.pathname));
  });

  // Initialize progress
  useEffect(() => {
    if (!Object.keys(progressObj).length) return false;

    const tick = () => {
      progressObj.render();
    };

    gsap.ticker.add(tick);

    return () => {
      gsap.ticker.remove(tick);
    };
  }, [progressObj]);

  // Update percentage
  useEffect(() => {
    if (!Object.keys(progressObj).length) return;

    progressObj.updateProgress(progress);
  }, [progress, progressObj]);

  return (
    <>
      <div className='loader'>
        <svg width='100%' height='100%'>
          <rect className='loader-inner bg' stroke='#777777' x='50%' y='50%' />
          <rect className='loader-inner' stroke='#FFF' ref={fg} x='50%' y='50%' />
        </svg>
      </div>
    </>
  );
};

export default Loader;
