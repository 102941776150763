import { CHANGE_BODY } from 'redux/constants/action-types';

const initialState = {
  body: '#090909',
};

const bodyReducer = (state = initialState, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }
  switch (action.type) {
    case CHANGE_BODY:
      return {
        ...state,
        body: state.body === '#FFF' ? '#090909' : '#FFF',
      };
    default:
      return state;
  }
};

export default bodyReducer;
