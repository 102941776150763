import { SAVE_REF } from 'redux/constants/action-types';

const initialState = {
  element: null,
};

const refReducer = (state = initialState, action) => {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case SAVE_REF:
      return { ...state, element: action.payload };
    default:
      return state;
  }
};

export default refReducer;
