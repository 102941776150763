import { combineReducers } from 'redux';

import bgReducer from './bgReducer';
import contentReducer from './contentReducer';
import ovReducer from './ovReducer';
import projectsReducer from './projectsReducer';
import refReducer from './refReducer';
import showNavReducer from './showNavReducer';

const rootReducer = combineReducers({
  projects: projectsReducer,
  bg: bgReducer,
  ov: ovReducer,
  ref: refReducer,
  showNavbar: showNavReducer,
  loaded: contentReducer,
});

export default rootReducer;
