import React from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import AnimatedAnchor from 'components/AnimatedAnchor/index';

import './footer.scss';

const Footer = () => {
  const location = useLocation();
  const { loaded } = useSelector((state) => state.loaded);

  return (
    <>
      {(location.pathname === '/' || location.pathname === '/404')
        && (
        <div id='footer' className='diff' style={{ visibility: loaded ? 'visible' : 'hidden' }}>
          <ul className='socials' data-anim-footer='socials'>
            <li className='_target'>
              <AnimatedAnchor href='https://twitter.com/ajmnzb' next='tw'>tw</AnimatedAnchor>
            </li>
            <li className='_target'>
              <AnimatedAnchor href='https://github.com/ajmnz' next='gh'>gh</AnimatedAnchor>
            </li>
            <li className='_target'>
              <AnimatedAnchor href='https://www.linkedin.com/in/ajmnz' next='in'>in</AnimatedAnchor>
            </li>
            <li className='_target'>
              <AnimatedAnchor href='mailto:arnau@ajb.cat' next='email'>email</AnimatedAnchor>
            </li>
          </ul>
          <ul className='lang' data-anim-footer='lang'>
            <li className='_target active'>
              <AnimatedAnchor href='/ca' next='ca'>ca</AnimatedAnchor>
            </li>
            <li className='_target'>
              <AnimatedAnchor href='/en' next='en'>en</AnimatedAnchor>
            </li>
          </ul>
        </div>
        )}
    </>
  );
};

export default Footer;
