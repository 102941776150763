import {
  FETCH_ERROR, FETCH_PROGRESS, FETCH_REQUEST, FETCH_SUCCESS,
} from 'redux/constants/action-types';

const initialState = {
  status: 'idle',
  error: null,
  data: [],
  progress: 0,
};

function projectsReducer(state = initialState, action) {
  if (typeof state === 'undefined') {
    return initialState;
  }

  switch (action.type) {
    case FETCH_REQUEST:
      return { ...state, status: 'fetching' };
    case FETCH_SUCCESS:
      return { ...state, status: 'fetched', data: action.payload };
    case FETCH_ERROR:
      return { ...state, status: 'error', error: action.payload };
    case FETCH_PROGRESS:
      return { ...state, progress: action.payload };
    default:
      return state;
  }
}

export default projectsReducer;
