import React, { useEffect, useRef, useState } from 'react';

import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import imagesLoaded from 'imagesloaded';
import { useSelector } from 'react-redux';
import { useTransition } from 'react-route-transition';
import { useLastLocation } from 'react-router-last-location';
import Splitting from 'splitting';

import { useAboutAnimation, useCustomAnimation } from 'animations/index';
import arnau from 'assets/img/arnau-greeny.jpg';
import useCallbackRef from 'hooks/useCallbackRef';
import Navbar from 'parts/Navbar/index';

import './about.scss';
import 'splitting/dist/splitting-cells.css';
import 'splitting/dist/splitting.css';

const About = () => {
  const { loaded } = useSelector((state) => state.loaded);

  const handler = useCustomAnimation(useAboutAnimation);
  useTransition(handler);

  const lastLocation = useLastLocation();

  const customEase = useRef(CustomEase.create('custom', '0.76, 0, 0.24, 1'));
  const [tl] = useState(gsap.timeline({
    paused: true,
    defaults: {
      ease: customEase.current,
    },
  }));

  const textBlock = useCallbackRef((node) => {
    const results = Splitting({
      target: node,
      by: 'lines',
    });

    const items = [...node.children];

    for (let i = 0; i < items.length; i += 1) {
      const el = items[i];
      const span = document.createElement('span');
      span.innerHTML = el.innerHTML;
      el.innerHTML = '';
      el.append(span);
    }

    const children = [];

    for (let i = 0; i < results[0].lines.length; i += 1) {
      const temp = [];
      for (let j = 0; j < results[0].lines[i].length; j += 1) {
        temp.push(results[0].lines[i][j].firstElementChild);
      }

      children.push(temp);
    }

    imagesLoaded(document.body, () => {
      if (lastLocation === null) {
        gsap.timeline()
          .set('[data-anim-navbar]', { opacity: 0, y: -10 })
          .set('[data-anim-about="image"]', { opacity: 0 })
          .set('[data-anim-about="heading-s"] [data-word], [data-anim-about="heading-f"] [data-word]', { yPercent: 100 })
          .set(children, { yPercent: 100 });

        tl
          .addLabel('everything')
          .to('[data-anim-about="image"]', {
            opacity: 1,
            duration: 1,
          }, 'everything')
          .to('[data-anim-about="heading-s"] [data-word]', {
            yPercent: 0,
            stagger: 0.05,
            duration: 1,
          }, 'everything')
          .to('[data-anim-about="heading-f"] [data-word]', {
            yPercent: 0,
            stagger: 0.05,
            duration: 1,
          }, 'everything+=0.1')
          .to(children, {
            yPercent: 0,
            stagger: 0.02,
            duration: 1.5,
          }, 'everything')
          .fromTo("[data-anim-about='items'] > *", {
            yPercent: 100,
          }, {
            yPercent: 0,
            stagger: 0.05,
          }, 'everything+=1.2')
          .fromTo("[data-anim-about='contact']", {
            y: -10,
            opacity: 0,
          }, {
            y: 0,
            opacity: 1,
          }, 'everything+=1.2')
          .fromTo('[data-anim-about="copy"]', {
            yPercent: 100,
          }, {
            yPercent: 0,
          }, 'everything+=1.2')
          .to('[data-anim-navbar]', {
            opacity: 1,
            y: 0,
            duration: 1,
          }, 'everything+=1.5');
      }
    });
  });

  // Page enter first load
  useEffect(() => {
    if (lastLocation === null && loaded) {
      tl.play();
    }
  }, [lastLocation, loaded, tl]);

  const getYear = () => {
    const d = new Date();
    return d.getFullYear();
  };

  return (
    <>
      <Navbar />
      <div className='main-wrapper' style={{ visibility: loaded ? 'visible' : 'hidden' }}>
        <div className='wrapper-inner'>
          <div className='heading-wrapper h-bg'>
            <h2 className='heading-main heading-fill' data-anim-about='heading-f'>
              <span data-word>Arnau</span>
              <span data-word>&nbsp;</span>
              <span data-word>Jiménez</span>
              <span data-word>&nbsp;</span>
              <span data-word>Buxadé</span>
            </h2>
          </div>
          <div className='body-wrapper'>
            <div className='media-wrapper'>
              <div className='image-wrapper'>
                <img src={arnau} alt='' data-anim-about='image' />
              </div>
              <div className='contact-wrapper diff'>
                <p data-anim-about='contact'>Contacte</p>
                <div className='items' data-anim-about='items'>
                  <a href='mailto:arnau@ajb.cat'>email</a>
                  <span className='divider'> — </span>
                  <a href='https://www.linkedin.com/in/ajmnz'>linkedin</a>
                  <span className='divider'> — </span>
                  <a href='https://twitter.com/ajmnzb'>twitter</a>
                  <span className='divider'> — </span>
                  <a href='https://github.com/ajmnz'>github</a>
                </div>
              </div>
            </div>
            <div className='text-wrapper diff'>
              <p data-anim-about='text' ref={textBlock}>
                Sóc un desenvolupador de JavaScript Junior amb dos anys
                d’experiència en desenvolupament Front-End.
                Em considero una persona proactiva, treballadora i amb moltes ganes d’aprendre.
                Actualment treballo de freelance desenvolupant llocs web i aplicacions
                per a clients de tota mena.
                Haver estat dissenyador gràfic durant gairebé tres anys ha estat
                essencial per desenvolupar un gust especial cap al disseny i per
                formar-me com a un professional altament creatiu.
              </p>
            </div>
          </div>
          <div className='heading-wrapper'>
            <h2 className='heading-main heading-stroke' data-anim-about='heading-s'>
              <span data-word>Arnau</span>
              <span data-word>&nbsp;</span>
              <span data-word>Jiménez</span>
              <span data-word>&nbsp;</span>
              <span data-word>Buxadé</span>
            </h2>
          </div>
        </div>
        <div className='copy-wrapper'>
          <p data-anim-about='copy' className='copyright diff'>{`Tots els drets reservats © ${getYear()} Arnau Jiménez`}</p>
        </div>
      </div>
    </>
  );
};

export default About;
